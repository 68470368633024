<template>
  <div class="home-container">
    <div v-if="showOverlay" class="overlay">
      <div class="chat-log">
        <p v-for="(line, index) in chatLog" :key="index">{{ line }}</p>
      </div>
      <button class="skip-button" @click="skipAudio">Skip</button>
    </div>

    <img
      src="@/assets/images/smallImages/thumbtack.png"
      alt="Red Thumbtack"
      class="red-thumbtack"
      @click="showPostIt = !showPostIt"
    />
    <div v-if="showPostIt" class="post-it-note" @click="triggerPhoneRinging">
      Beverly Hills Club
    </div>
    <div v-if="isRinging" class="phone-ringing-lines"></div>
    <audio ref="phoneAudio" src="@/assets/audio/jepstein-call.mp3"></audio>
    <audio ref="ringingAudio" src="@/assets/audio/phonering.wav" loop></audio>

    <!-- Account Section with Sense Display -->
    <div class="account-section" @click="toggleAccountDropdown">
      <i class="fa fa-user-circle account-icon"></i>
      <div class="account-info">
        <span class="user-name">{{ userName }}</span>
        <span class="sense-balance">Cents: {{ userSense }}</span>
      </div>
    </div>
    <div v-if="dropdownOpen" class="dropdown-menu account-menu">
      <button class="dropdown-item" @click="handleBuySense">Buy Cents</button>
<!--      <button class="dropdown-item" @click="handlePaymentInfo">Payment Information</button>-->
      <button class="dropdown-item" @click="handleLogout">Logout</button>
    </div>

    <!-- Key Icon Section -->
    <div class="key-icon-section" @click="toggleKeyDropdown">
      <img src="@/assets/images/logoKey.png" alt="Key Icon" class="key-icon" />
    </div>
    <div v-if="keyDropdownOpen" class="dropdown-menu key-menu">
<!--      <button class="dropdown-item" @click="handleAbout">About Conspiracy Trivia</button>-->
<!--      <button class="dropdown-item" @click="handleSources">Sources and Additional Information</button>-->
      <button class="dropdown-item" @click="handleDonation">Donate to Support Development</button>
      <button class="dropdown-item" @click="handlePromo">Promotional Codes</button>
      <button class="dropdown-item" @click="handleTermsOfUse">Terms of Use</button>
      <button class="dropdown-item" @click="handleInstructions">Instructions</button>
      <button class="dropdown-item" @click="handleContactUs">Contact Us</button>
    </div>

    <!-- Desk Left Side (One large clickable area for drawers) -->
    <button class="desk-button desk-left" @click="handleDrawerClick('desk-left')"></button>

    <!-- Desk Right Side (One large clickable area for drawers) -->
    <button class="desk-button desk-right" @click="handleDrawerClick('desk-right')"></button>

    <!-- Computer Screen (Clickable) -->
    <button class="computer-button" @click="handleComputerClick"></button>

    <!-- Phone (Clickable) -->
    <button class="phone-button" @click="handlePhoneClick"></button>

    <!-- Filing Cabinets (Left and Right) -->
    <button class="filing-cabinet-button filing-cabinet-left" @click="handleFilingCabinetClick"></button>
    <button class="filing-cabinet-button filing-cabinet-right" @click="handleFilingCabinetClick"></button>
  </div>
</template>

<script>
/* eslint-disable */
import phoneRingingSound from '@/assets/audio/phone-ringing.wav';
import jepsteinCallSound from '@/assets/audio/jepstein-call.mp3';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  data() {
    return {
      dropdownOpen: false, // Controls the account dropdown
      keyDropdownOpen: false, // Controls the key icon dropdown
      isZoomed: false, // Track zoom state
      showPostIt: false,
      isRinging: false,
      showOverlay: false,
      ringingAudio: null, // Audio instance for phone ringing
      jepsteinAudio: null, // Audio instance for Jepstein call
      chatLog: [
        "Detective: “Hello? Community Intelligence.”",
        "Jeppstein: “Listen close, gumshoe, I’ve got something for you...”",
        // Add more lines as needed
      ],
      currentLineIndex: 0,
      chatInterval: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['agentName', 'sense']),
    userName() {
      return this.agentName || 'Agent';
    },
    userSense() {
      return this.sense || 0;
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    handleLogout() {
      this.logout();
      this.$router.push('/login');
    },
    handleBuySense() {
      this.$router.push('/paypal');
    },
    handleTermsOfUse() {
      this.$router.push('/termsofuseback');
    },
    handleContactUs() {
      this.$router.push('/contactus');
    },
    handleInstructions() {
      this.$router.push('/instructions');
    },
    handleDonation() {
      this.$router.push('/donation');
    },
    handlePromo() {
      this.$router.push('/promo');
    },
    handlePaymentInfo () {
     // alert('Redirect to Payment Information page.');
    },
    handleAbout() {
      //alert('About Conspiracy Trivia information.');
    },
    handleSources() {
      //alert('Sources and Additional Information.');
    },
    playChatLog() {
      this.currentLineIndex = 0;
      this.chatInterval = setInterval(() => {
        if (this.currentLineIndex < this.chatLog.length) {
          this.currentLineIndex++;
        } else {
          clearInterval(this.chatInterval);
        }
      }, 3000); // Adjust time per line
    },
    skipAudio() {
      this.stopAudio();
      this.$router.push("/trolley");
    },
    stopAudio() {
      if (this.chatInterval) clearInterval(this.chatInterval);

      if (this.ringingAudio) {
        this.ringingAudio.pause();
        this.ringingAudio.currentTime = 0;
      }

      if (this.jepsteinAudio) {
        this.jepsteinAudio.pause();
        this.jepsteinAudio.currentTime = 0;
      }

      this.showOverlay = false;
    },
    triggerPhoneRinging() {
      this.isRinging = true;
      this.showOverlay = false;

      this.ringingAudio = new Audio(phoneRingingSound);
      this.jepsteinAudio = new Audio(jepsteinCallSound);

      // Play phone ringing sound and loop
      this.ringingAudio.loop = true;
      this.ringingAudio
        .play()
        .catch((error) => console.error("Error playing ringing audio:", error));

      // Stop ringing and start Jepstein call after 4 seconds
      setTimeout(() => {
        this.isRinging = false; // Stop ringing animation
        this.ringingAudio.pause(); // Stop ringing sound
        this.ringingAudio.currentTime = 0; // Reset for future playback

        // Play the Jepstein call audio
        this.jepsteinAudio
          .play()
          .then(() => {
            this.showOverlay = true; // Show overlay and chat log
            this.playChatLog(); // Start chat log scroll
          })
          .catch((error) => console.error("Error playing Jepstein audio:", error));

        // Navigate to the next scene after the audio ends
        this.jepsteinAudio.onended = () => {
          this.stopAudio();
          this.$router.push("/trolley");
        };
      }, 4000);
    },
    toggleAccountDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      this.keyDropdownOpen = false; // Close the key dropdown if it's open
    },
    toggleKeyDropdown() {
      this.keyDropdownOpen = !this.keyDropdownOpen;
      this.dropdownOpen = false; // Close the account dropdown if it's open
    },
    handleDrawerClick(position) {
      this.isZoomed = true;
      this.$router.push('/drawer');
    },
    handleComputerClick() {
      this.$router.push('/desktop');
    },
    handlePhoneClick() {
      alert('Phone clicked!');
    },
    handleFilingCabinetClick() {
      this.$router.push('/desktop'); // Navigate to DesktopView
    }
  }
};
</script>


<style scoped>
/* General reset */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.chat-log {
  max-width: 90%;
  max-height: 70%;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
}

.chat-log p {
  margin: 10px 0;
  font-size: 1rem;
  line-height: 1.5;
}

.skip-button {
  padding: 10px 20px;
  background-color: #ff4d4d;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
}

.skip-button:hover {
  background-color: #ff0000;
}

.red-thumbtack {
  position: absolute;
  top: 10%; /* Adjust the value as needed to fine-tune vertical placement */
  left: 45%; /* Center the image horizontally */
  transform: translate(-50%, -50%); /* Center the image precisely */
  width: 40px; /* Adjust width for desired size */
  height: auto; /* Maintain aspect ratio */
  z-index: 1001; /* Ensure it appears above other elements */
}

.post-it-note {
  position: absolute;
  top: 12%; /* Adjust to appear near the thumbtack */
  left: 50%; /* Centered horizontally near the thumbtack */
  transform: translate(-50%, -50%);
  background-color: #fdfd96; /* Light yellow for the post-it note */
  color: #333; /* Dark color for the text */
  font-family: 'Patrick Hand', cursive; /* Handwriting-style font */
  font-size: 1.2rem;
  border: 1px solid #ccc; /* Slight border for realism */
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
  padding: 10px;
  border-radius: 4px; /* Rounded edges for the note */
  z-index: 1002; /* Ensure it's above the thumbtack */
  white-space: nowrap; /* Prevent text wrapping */
  text-align: center;
  max-width: 200px; /* Optional: limit the width */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home-container {
  height: 100vh;
  width: 100vw;
  background: url('@/assets/images/desk.webp') no-repeat center center;
  background-size: 100% 100%; /* Stretch the image to cover the entire viewport */
  position: relative;
  overflow: hidden;
  display: flex;
}

/* Account Section with Sense Display */
.account-section {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.account-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.account-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: bold;
}

.sense-balance {
  font-size: 0.9rem;
}

/* Key Icon Section */
.key-icon-section {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.key-icon-section i {
  font-size: 1.5rem;
}

/* Dropdown Menu Styles */
.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 1000;
  min-width: 150px;
}

.account-menu {
  top: 3.5rem;
  left: 1rem;
}

.key-menu {
  top: 3.5rem;
  right: 1rem;
}

.dropdown-item {
  background-color: white;
  border: none;
  padding: 0.5rem 1rem;
  text-align: left;
  cursor: pointer;
  width: 100%;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.key-icon {
  width: 36px; /* Adjust size as needed */
  height: 36px;
  cursor: pointer;
}
/* Desk Left Side (One large button) */
.desk-button.desk-left {
  position: absolute;
  top: calc(71%); /* Adjusted for desk drawers */
  left: calc(35%);
  width: calc(11% + 2vw); /* Dynamic width using calc */
  height: calc(25% + 2vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(-50%, -50%);
}

/* Desk Right Side (One large button) */
.desk-button.desk-right {
  position: absolute;
  top: calc(71%);
  right: calc(32.5%);
  width: calc(11% + 2vw); /* Dynamic width using calc */
  height: calc(25% + 2vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(50%, -50%);
}

/* Computer Screen Button */
.computer-button {
  position: absolute;
  top: calc(42%);
  left: calc(50.5%);
  width: calc(13% + 2vw); /* Dynamic width using calc */
  height: calc(20% + 2vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
@keyframes phone-glow {
  0%, 100% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.8), 0 0 30px rgba(255, 0, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 0, 0, 1), 0 0 40px rgba(255, 0, 0, 0.8);
  }
}
@keyframes ringing-lines {
  0%, 100% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
}
.phone-ringing-lines {
  position: absolute;
  top: calc(45%); /* Adjusted to appear above the phone */
  left: calc(63%); /* Centered horizontally near the phone button */
  width: 90px;
  height: 80px;
  z-index: 1002;
  pointer-events: none; /* Ensure it doesn't interfere with clicks */
}
.phone-ringing-lines::before,
.phone-ringing-lines::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(255, 0, 0, 0.8);
  border-radius: 50%;
  animation: ringing-lines 1s ease-in-out infinite;
}
.phone-ringing-lines::after {
  animation-delay: 0.5s;
}
.phone-button.ringing {
  animation: phone-glow 1s ease-in-out infinite;
}

@media (max-width: 600px) {
  .phone-ringing-lines {
    top: calc(37%); /* Align vertically for phones */
    left: calc(58%); /* Align horizontally for phones */
    width: 70px; /* Smaller size for small screens */
    height: 70px;
  }
}
@media (min-width: 601px) and (max-width: 1010px) {
  .phone-ringing-lines {
    top: calc(37%); /* Fine-tune vertical alignment */
    left: calc(60%); /* Center horizontally on tablets */
    width: 100px; /* Medium size */
    height: 100px;
  }
}
@media (min-width: 1010px) and (max-width: 1200px){
  .phone-ringing-lines {
    top: calc(43%); /* Align above the phone for large screens */
    left: calc(60%); /* Center horizontally for desktops */
    width: 120px; /* Larger size for desktops */
    height: 120px;
  }
}

@media (min-width: 1200px) {
  .phone-ringing-lines {
    top: calc(41%); /* Align above the phone for large screens */
    left: calc(61%); /* Center horizontally for desktops */
    width: 250px; /* Larger size for desktops */
    height: 250px;
  }
}


/* Phone Button */
.phone-button {
  position: absolute;
  top: calc(50%);
  left: calc(66%);
  width: calc(8% + 1.5vw); /* Dynamic width using calc */
  height: calc(10% + 1.5vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.filing-cabinet-button.filing-cabinet-left {
  position: absolute;
  top: calc(60%); /* Adjust the position relative to the image */
  left: calc(16%);
  width: calc(15% + 1.5vw); /* Dynamic width using calc */
  height: calc(60% + 2vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

/* Right Filing Cabinet Button */
.filing-cabinet-button.filing-cabinet-right {
  position: absolute;
  top: calc(60%);
  right: calc(10.5%);
  width: calc(14% + 1.5vw); /* Dynamic width using calc */
  height: calc(60% + 2vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
}
/* Media query adjustments for smaller screens */
@media (max-width: 400px) {
  .account-section {
    font-size: 0.8rem;
    padding: 0.4rem 0.7rem;
  }

  .key-icon-section {
    padding: 0.4rem 0.7rem;
  }

  .desk-button.desk-left,
  .desk-button.desk-right {
    width: calc(22% + 1vw);
    height: calc(38% + 1.5vh);
    top: calc(70%);
  }

  .computer-button {
    width: calc(21% + 1vw);
    height: calc(21% + 1.5vh);
    top: calc(40%);
  }

  .phone-button {
    width: calc(17% + 1.5vw);
    height: calc(12% + 1.5vh);
    top: calc(50%);
    left: calc(66%);
  }
}

@media (min-width: 600px) {
  .red-thumbtack {
    width: 60px; /* Increase size for medium screens */
  }
}

/* Media Query 3: Large screens (desktops or landscape tablets) */
@media (min-width: 1024px) {
  .red-thumbtack {
    width: 80px; /* Further increase size for large screens */
  }
}

/* Media Query 4: Extra-large screens (very large desktops or TVs) */
@media (min-width: 1440px) {
  .red-thumbtack {
    width: 100px; /* Max size for extra-large screens */
  }
}

</style>

