<template>
  <div class="video-container">
    <!-- Video Player -->
    <video
      ref="videoPlayer"
      :src="videoSource"
      @ended="navigateToDisclaimer"
      autoplay
      muted
      playsinline
      class="full-video"
    ></video>

    <!-- Unmute Button -->
    <button v-if="isMuted" @click="unmuteVideo" class="unmute-button">
      🔊 Unmute
    </button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "VideoView",
  data() {
    return {
      videoSource: require("@/assets/video/rabbitINTROfull.mp4"), // Path to your MP4 video
      isMuted: true, // Video starts muted by default
    };
  },
  methods: {
    navigateToDisclaimer() {
      this.$router.push("/disclaimer"); // Navigate to the next view after video ends
    },
    unmuteVideo() {
      const video = this.$refs.videoPlayer;
      video.muted = false; // Unmute the video
      this.isMuted = false; // Hide the unmute button
    },
  },
};
</script>

<style scoped>
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: black; /* Optional background color */
  margin: 0;
  position: relative; /* Ensure unmute button is positioned relative to the container */
}

.full-video {
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures video fills the container nicely */
}

.unmute-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for better contrast */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  z-index: 100; /* Ensure button appears above the video */
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease;
}

.unmute-button:hover {
  background-color: rgba(255, 255, 255, 0.9); /* Slight hover effect */
  color: black;
}
</style>
