<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const showEmail = ref(false)

function goBack () {
  router.push('/home')
}

function displayEmail () {
  showEmail.value = true
}
</script>

<template>
  <div class="contact-page-container">
    <!-- Back Button -->
    <button class="back-button" @click="goBack">
      <i class="icon-left"></i> Back
    </button>

    <!-- Card Container -->
    <div class="contact-card">
      <h1 class="contact-title">Contact Us</h1>
      <p v-if="!showEmail" class="contact-description">
        Need to reach us? Click the button below to see our contact email.
      </p>
      <p v-else class="contact-email">
        <i class="icon-envelope"></i> <span>ConspiracyTriviaContact@pm.me</span>
      </p>

      <button v-if="!showEmail" class="show-email-button" @click="displayEmail">
        Show Contact Email
      </button>
    </div>
  </div>
</template>

<style scoped>
/* Fullscreen background styling */
.contact-page-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2b2b2b; /* Dark background */
  color: #ffffff;
  font-family: Arial, sans-serif;
  padding: 1rem;
  position: relative;
}

/* Back Button Styling */
.back-button {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  background: none;
  color: #f0f0f0;
  font-size: 1rem;
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button:hover {
  background-color: #f0f0f0;
  color: #2b2b2b;
}

/* Contact Card */
.contact-card {
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  max-width: 500px;
  width: 100%;
  animation: fadeIn 0.5s ease-in-out;
}

.contact-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #f8f8f8;
}

.contact-description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #c0c0c0;
}

.contact-email {
  font-size: 1.2rem;
  font-weight: bold;
  color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.show-email-button {
  background-color: #4caf50;
  color: #fff;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.show-email-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

/* Icons */
.icon-left {
  display: inline-block;
  border: solid #f0f0f0;
  border-width: 0 3px 3px 0;
  padding: 3px;
  transform: rotate(135deg);
  margin-right: 5px;
}

.icon-envelope {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('https://upload.wikimedia.org/wikipedia/commons/4/4e/Font_Awesome_5_solid_envelope.svg')
  no-repeat center center;
  background-size: contain;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Adjustments */
@media (max-width: 600px) {
  .contact-title {
    font-size: 1.5rem;
  }

  .contact-description,
  .contact-email {
    font-size: 1rem;
  }

  .show-email-button {
    font-size: 0.9rem;
  }
}
</style>
