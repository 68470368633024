/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import OpeningView from '../views/OpeningView.vue'
import WarningView from '../views/WarningView.vue'
import WelcomeView from '../views/WelcomeView.vue'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import Login from '../components/auth/Login.vue'
import CreateAccount from '../components/auth/CreateAccount.vue'
import FolderView from '@/views/FolderView.vue';
import DrawerView from '@/views/DrawerView.vue';
import DesktopView from '@/views/DesktopView.vue';
import QuestionsView from "@/views/QuestionsView.vue";
import TermsOfUseView from "@/views/TermsOfUseView.vue";
import TermsOfUseBackView from "@/views/TermsOfUseBackView.vue";
import Disclaimer from "@/views/DisclaimerView.vue";
import Paypal from "@/views/PaypalView.vue";
import Donation from "@/views/DonationView.vue";
import Paperclips from "@/views/PaperClipsView.vue";
import PromoView from "@/views/PromoView.vue";
import Guest from "@/views/GuestView.vue";
import RabbitGifView from "@/views/RabbitGifView.vue";
import TrolleyGifView from "@/views/TrolleyGifView.vue";
import ContactUs from "@/views/ContactUsView.vue";
import Instructions from "@/views/InstructionsView.vue";


const routes = [
  {
    path: '/',
    component: OpeningView
  },
  {
    path: '/warning',
    component: WarningView
  },
  {
    path: '/welcome',
    component: WelcomeView
  },
  {
    path: '/login',
    component: LoginView,
    children: [
      {
        path: '',
        component: Login
      },
      {
        path: 'create-account',
        component: CreateAccount
      }
    ]
  },
  {
    path: '/home',
    component: HomeView,
    meta: { requiresAuth: true } // Protect home route
  },
  {
    path: '/folder',
    name: 'Folder',
    component: FolderView,
  },
  {
    path: '/promo',
    name: 'promo',
    component: PromoView,
  },
  {
    path: '/drawer',
    name: 'Drawer',
    component: DrawerView,
  },
  {
    path: '/desktop',
    name: 'Desktop',
    component: DesktopView,
  },
  {
    path: '/termsofuse',
    name: 'TermsOfUse',
    component: TermsOfUseView,
  },
  {
    path: '/termsofuseback',
    name: 'TermsOfUseBack',
    component: TermsOfUseBackView,
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer,
  },
  {
    path: '/paypal',
    name: 'paypal',
    component: Paypal,
  },
  {
    path: '/paperclips',
    name: 'paperclips',
    component: Paperclips,
  },
  {
    path: '/donation',
    name: 'donation',
    component: Donation,
  },
  {
    path: '/guest',
    name: 'guest',
    component: Guest,
  },
  {
    path: '/rabbit',
    name: 'rabbit',
    component: RabbitGifView,
  },
  {
    path: '/trolley',
    name: 'trolley',
    component: TrolleyGifView,
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: ContactUs,
  },
  {
    path: '/instructions',
    name: 'instructions',
    component: Instructions,
  },
  {
    path: '/questions/:categoryId/:dossierId', // Updated path to include categoryId and dossierId
    name: 'QuestionsView',
    component: QuestionsView, // Import this component
    props: true // Enable route params as props
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Route guards for protected routes
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const isGuest = store.getters['auth/isGuest']; // Check if the user is a guest
  const firstLogin = store.getters['auth/firstLogin'];

  // Allow access to public routes and exclude WelcomeView from the firstLogin redirect
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated && !isGuest) { // Redirect if neither authenticated nor a guest
      next('/login');
    } else if ((isAuthenticated || isGuest) && firstLogin && to.path !== '/welcome') {
      next('/warning'); // Redirect to warning page on first login or guest login unless it's the welcome view
    } else {
      next();
    }
  } else {
    next(); // Proceed to public routes
  }
});

export default router;
