<template>
  <div class="instructions-container">
    <div class="instructions-content">
      <h1>Game Instructions</h1>
      <p><strong>Welcome to Conspiracy Trivia!</strong></p>

      <h2>Game Play Details</h2>
      <ul>
        <li><strong>Intro Dossier:</strong> The game begins with the <em>Intro Dossier</em> containing <strong>33 questions</strong>.</li>
        <li><strong>Accessing Dossiers:</strong> In the Detective Office Home Screen, click the <em>Computer</em> or a <em>Filing Cabinet</em> to navigate to the Categories and Dossiers Selection page.</li>
        <li><strong>Purchasing Dossiers:</strong> Dossiers cost <strong>$1 (100 "sense" points)</strong> each.</li>
        <li><strong>Points System:</strong>
          <ul>
            <li>Points are <strong>gained for correct answers</strong> and <strong>lost for incorrect answers</strong>.</li>
            <li>Points are only gained/lost on your <strong>first attempt</strong> at a dossier.</li>
          </ul>
        </li>
        <li><strong>Replay Dossiers:</strong> After purchasing, dossiers can be replayed for free.</li>
        <li><strong>Save Progress:</strong> If you leave a dossier before completing it, your progress will be saved <strong>(if logged in)</strong>.</li>
        <li><strong>Meet Characters:</strong> Click the <em>red pushpin</em> on the map to meet your first shady character.</li>
        <li><strong>Paper-Clips:</strong>
          <ul>
            <li>During trivia or after completing a dossier, use the <em>Paper-Clip icon</em> to save a Question or Dossier to your notes.</li>
            <li>Click the <em>Office Desk Drawer</em> to view your <strong>Saved Dossiers</strong> and <strong>Paper-Clip stash</strong>.</li>
            <li>Paper Clips cost <strong>10 points</strong> each and can be purchased at any time.</li>
          </ul>
        </li>
        <li><strong>Explore & Bookmark Content:</strong> Use Paper Clips to save intriguing content, chart connections, or bookmark topics for later exploration.</li>
        <li><strong>Recommended Dossiers:</strong> After completing a dossier, the game may recommend a <em>Next Dossier</em>.</li>
        <li><strong>Promo Codes:</strong> Promo Codes can be used at any time but:
          <ul>
            <li>Each promo code can <strong>only be used once</strong>.</li>
            <li>Each user can <strong>only use one Promo Code</strong>.</li>
          </ul>
        </li>
      </ul>

      <p>Enjoy solving conspiracies and uncovering truths in <strong>Conspiracy Trivia™!</strong></p>
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>

    <button class="next-button" @click="goToCategories">Back</button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'InstructionsView',
  methods: {
    goToCategories() {
      this.$router.push('/home'); // Adjust the route name/path as needed
    },
  },
};
</script>
<style scoped>
/* General container styling */
.instructions-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  color: #333;
  font-family: Arial, sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Content area */
.instructions-content {
  flex: 1 1 auto;
  padding: 20px;
  overflow-y: auto;
  text-align: left;
}

h1 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

p {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

strong {
  color: #007BFF;
}

/* Next button */
.next-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
  text-align: center;
  z-index: 10; /* Ensures button stays on top */
}

.next-button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .instructions-content {
    padding: 10px;
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  .next-button {
    font-size: 0.9rem;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  .next-button {
    font-size: 0.85rem;
    padding: 10px;
  }
}
</style>
