<template>
  <div class="media-container">
    <!-- Unmute Button -->
    <button v-if="isMuted" @click="unmuteVideo" class="unmute-button">🔊 Unmute</button>

    <!-- Initial Video -->
    <template v-if="showInitialVideo">
      <video
        :src="initialVideoSource"
        autoplay
        muted
        playsinline
        class="full-video"
        ref="initialVideo"
        @canplay="checkAutoplay"
        @ended="playHallwayScene"
      ></video>
    </template>

    <!-- Hallway Scene -->
    <template v-else-if="showHallwayVideo">
      <video
        :src="hallwayVideoSource"
        autoplay
        playsinline
        class="full-video"
        ref="hallwayVideo"
        @ended="showOfficeScene"
      ></video>
    </template>

    <!-- Office Scene with Popup -->
    <template v-else-if="showOffice">
      <img :src="officeImageSource" class="full-image" />
      <div class="popup">
        <h2>Recommended Dossiers</h2>
        <ul>
          <li>Maxwell 1</li>
          <li>Bill Gates Mansion and Child P*rn?</li>
          <li>Balenciaga: Controversy</li>
        </ul>
      </div>

      <!-- Back Button -->
      <button class="back-button" @click="navigateHome">Back</button>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'GifAndVideoView',
  data() {
    return {
      initialVideoSource: require('@/assets/video/EpsteinWholeScene.mp4'), // Combined video
      hallwayVideoSource: require('@/assets/video/hallway.mp4'), // Hallway video
      officeImageSource: require('@/assets/images/office.webp'), // Office background
      showInitialVideo: true,
      showHallwayVideo: false,
      showOffice: false,
      isMuted: true, // Video starts muted
    };
  },
  methods: {
    checkAutoplay() {
      const videoElement = this.$refs.initialVideo;
      if (videoElement) {
        videoElement.muted = true; // Enforce muted autoplay for mobile
        videoElement.play().catch(() => {
          console.warn('Autoplay failed, video is muted.');
        });
      }
    },
    unmuteVideo() {
      const videoElement = this.$refs.initialVideo;
      if (videoElement) {
        videoElement.muted = false;
        this.isMuted = false;
      }
    },
    playHallwayScene() {
      this.showInitialVideo = false;
      this.showHallwayVideo = true;
    },
    showOfficeScene() {
      this.showHallwayVideo = false;
      this.showOffice = true;
    },
    navigateHome() {
      this.$router.push('/home'); // Navigate back to "Home"
    },
  },
};
</script>

<style scoped>
/* Media Container */
.media-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: black;
}

/* Full-Screen Media */
.full-video,
.full-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Unmute Button */
.unmute-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  z-index: 100;
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease;
}

.unmute-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
}

/* Popup Styling */
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(34, 34, 34, 0.9);
  color: white;
  padding: 25px;
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
  animation: fade-in 1s ease-in-out;
}

.popup h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  text-transform: uppercase;
  border-bottom: 2px solid #f4f4f4;
  padding-bottom: 10px;
}

.popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup li {
  font-size: 1.4rem;
  margin: 15px 0;
  color: #f4f4f4;
}

.popup li:hover {
  color: #cccccc;
}

/* Back Button */
.back-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  z-index: 100;
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
}

/* Fade-in Animation */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
</style>
